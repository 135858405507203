<template>
	<div id="shop">
		<Navigation :conditions="fromData.condition" :isShop="true" @searchClick="searchClick"
			@localClick="localClick" />
		<ClassifyChoose :classifyList="classifyList" :isShop="true" @screenClick="screenClick"></ClassifyChoose>
		<Breadcrumb :navigationList='navigationList' @chooseIndexClick="chooseIndexClick"></Breadcrumb>
		
			<div class="shopping">
				<div class="shopbox-class">
					<div class="shopbox-class-i" v-for="(item,index) in bottomClassifyList" :key="index">
						<div class="shopbox-class-i-l">
							{{item.title}}
						</div>
						<div class="shopbox-class-i-r flw">
							<div :class="thereClassifyIndex[index] === index2 ? 'shopbox-class-i-r-a' : 'shopbox-class-i-r-i'"
								v-for="(item2,index2) in item.list" :key="index2"
								@click="chooseClick(item.type,item2,index,index2)">
								{{item2.title}} <span v-if="thereClassifyIndex[index] === index2"
									@click.stop="delChooseClick(index)">x</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<ShopList :shopList="shopList"></ShopList>
			
			<div class="pagingaClass">
				<el-pagination class="pagingaClass-i" background layout="prev, pager, next" :total="total" :page-size="20"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>

		<SideNavigation></SideNavigation>
	</div>
</template>

<script>
	import Navigation from "../common/navigation.vue"
	import ClassifyChoose from "../common/classify_choose.vue"
	import Breadcrumb from "../common/breadcrumb.vue"
	import ShopList from "./shop_list.vue"
	import SideNavigation from "../common/side_navigation.vue"

	import {
		goodsgetAllCategory,
		goodslist,
		companycompanyList
	} from "../../utils/index.js"

	export default {
		name: "shop",
		components: {
			Navigation,
			ClassifyChoose,
			Breadcrumb,
			ShopList,
			SideNavigation,
		},
		data() {
			return {
				navigationList: [{
					name: "首页",
					url: "/",
					type: 0
				}],
				// 分类列表
				classifyList: [],
				// 面包屑参数
				classifyIndex: "",
				// 层级
				tier: "",
				// 下方分类
				bottomClassifyList: [],
				fromData: {
					page: 1,
					size: 20,
					pid: "",
					cid: "",
					condition: "",
					jsonStr: "",
					province: "",
					city: ""
				},
				// 三级选定分类
				thereClassifyIndex: [],
				// 商品列表
				shopList: [],
				total: 0,
			}
		},
		created() {
			let str = localStorage.getItem("$local") || ''
			if (str != '') {
				let arr = str.split(',')
				for (var i = 0; i < arr.length; i++) {
					if (i == 0) {
						this.fromData.province = arr[i]
					} else {
						this.fromData.city = arr[i]
					}
				}
			}

			if (this.$route.query.cc) {
				this.classifyIndex = this.$route.query.cc.toString()
			}
			if (this.$route.query.condition) {
				this.fromData.condition = this.$route.query.condition.toString()
			}
			if (this.$route.query.shoptypes) {
				this.shoptypes = this.$route.query.shoptypes.toString()
			}
			
			this.goodsgetAllCategory()
		},
		methods: {
			// 切换地区
			localClick() {
				this.shopList = []
				this.fromData.page = 1
				let str = localStorage.getItem("$local") || ''
				if (str != '') {
					let arr = str.split(',')
					for (var i = 0; i < arr.length; i++) {
						if (i == 0) {
							this.fromData.province = arr[i]
						} else {
							this.fromData.city = arr[i]
						}
					}
				}
				// if(this.shoptypes==0){
					this.goodslist()
				// }else{
				// 	this.companyList()
				// }
				
			},

			// 全部分类的筛选
			screenClick(cc) {
				this.tier = 0
				this.navigationList = [{
					name: "首页",
					url: "/",
					type: 0
				}]
				this.thereClassifyIndex = []
				this.fromData.condition = ""
				this.fromData.pid = ""
				this.fromData.cid = ""
				this.fromData.jsonStr = ""
				this.shopList = []
				this.fromData.page = 1

				this.classifyIndex = cc.toString()
				//console.log("this.classifyIndex", this.classifyIndex);
				// 处理面包屑
				this.disposeClassify()
			},

			// 获取所有分类
			goodsgetAllCategory() {
				goodsgetAllCategory().then(res => {
					//console.log("获取所有分类", res);
					this.classifyList = res.data
					if (this.classifyIndex !== "") {
						// 处理面包屑
						this.disposeClassify()
					} else {
						// 没有选分类一级分类
						this.firstClassify()
					}
				})
			},
			// 处理面包屑
			disposeClassify() {
				let arr = this.classifyIndex.split("-")
				this.tier = arr.length
				for (var i = 0; i < arr.length; i++) {
					if (i == 0) {
						this.fromData.pid = arr[0]
						this.classifyList.forEach((item, index) => {
							if (item.id == arr[0]) {
								this.navigationList.push({
									name: item.title,
									url: index,
									type: 1
								})
							}
						})
					}

					if (i == 1) {
						this.fromData.cid = arr[1]
						this.classifyList[this.navigationList[1].url].children.forEach((item, index) => {
							if (item.id == arr[1]) {
								this.navigationList.push({
									name: item.title,
									url: index,
									type: 1
								})
							}
						})
					}

					if (i == 2) {

						let thereArr = JSON.parse(this.classifyList[this.navigationList[1].url].children[this
							.navigationList[2].url].children[0].json);
						thereArr.forEach((item, index) => {
							if (item.v == arr[2]) {
								let jsonlist = [{
									k: this.classifyList[this.navigationList[1].url].children[this
										.navigationList[2].url].children[0].title,
									v: arr[2]
								}]
								this.fromData.jsonStr = JSON.stringify(jsonlist)

								this.navigationList.push({
									name: item.key,
									url: index,
									type: 1
								})
							}
						})
					}
				}

				// 处理下方分类
				this.bottomClassify()
				// 商品列表
				// if(this.shoptypes==0){
					this.goodslist()
				// }else{
				// 	this.companyList()
				// }
				// console.log("this.navigationList", this.navigationList);
			},
			// 处理下方分类
			bottomClassify() {
				this.bottomClassifyList = []
				if (this.tier == 1) {
					this.bottomClassifyList.push({
						title: "分类",
						type: 1,
						list: this.classifyList[this.navigationList[1].url].children
					})
				} else {
					this.classifyList[this.navigationList[1].url].children[this.navigationList[2].url].children.forEach(
						(item, index) => {
							let list = []
							JSON.parse(item.json).forEach(item2 => {
								list.push({
									title: item2.key,
									id: item2.v
								})
							})

							this.bottomClassifyList.push({
								title: item.title,
								type: 2,
								list
							})
							if (this.tier == 3) {
								this.thereClassifyIndex.push(index == 0 ? this.navigationList[3].url : '')
							}
						})
				}
				// console.log("thereClassifyIndex", this.thereClassifyIndex);
			},
			// 一级分类
			firstClassify() {
				this.bottomClassifyList = []
				this.bottomClassifyList.push({
					title: "分类",
					type: 0,
					list: this.classifyList
				})
				// 商品列表
				this.goodslist()
				
			},
			// 商品列表
			goodslist() {
				goodslist(this.fromData).then(res => {
					//console.log("商品列表", res);
					this.shopList = res.data.records
					this.total = res.data.total * 1
					this.$forceUpdate()
				})
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
				this.fromData.page = val
				this.goodslist()
			},
			// 选择分类
			chooseClick(type, item, index, index2) {
				this.fromData.condition = ""
				if (type == 0) {
					this.fromData.pid = item.id
					this.navigationList.push({
						name: item.title,
						url: index2,
						type: 1
					})
					this.bottomClassifyList = []
					this.bottomClassifyList.push({
						title: "分类",
						type: 1,
						list: this.classifyList[this.navigationList[1].url].children
					})
				} else if (type == 1) {
					this.fromData.cid = item.id
					this.navigationList.push({
						name: item.title,
						url: index2,
						type: 1
					})
					this.bottomClassifyList = []
					this.classifyList[this.navigationList[1].url].children[this.navigationList[2].url].children.forEach(
						item => {
							let list = []
							JSON.parse(item.json).forEach(item2 => {
								list.push({
									title: item2.key,
									id: item2.v
								})
							})

							this.bottomClassifyList.push({
								title: item.title,
								type: 2,
								list
							})
						})
				} else if (type == 2) {
					this.thereClassifyIndex[index] = index2
					if (index == 0) {
						if (this.navigationList.length == 3) {
							this.navigationList.push({
								name: item.title,
								url: index2,
								type: 1
							})
						} else {
							this.navigationList[3].name = item.title
							this.navigationList[3].url = index2
						}
					}

					let jsonlist = []
					this.thereClassifyIndex.forEach((item, index) => {
						if (item !== '') {
							jsonlist.push({
								k: this.bottomClassifyList[index].title,
								v: this.bottomClassifyList[index].list[item].id
							})
						}

					})

					this.fromData.jsonStr = jsonlist.length == 0 ? '' : JSON.stringify(jsonlist)
				}
				this.shopList = []
				this.fromData.page = 1
				this.goodslist()
				this.$forceUpdate()
			},
			// 删除分类
			delChooseClick(index) {
				// console.log(index);
				this.thereClassifyIndex[index] = ""
				if (index == 0) {
					this.navigationList.splice(3, 1)
					//console.log(this.navigationList);
				}
				let jsonlist = []
				this.thereClassifyIndex.forEach((item, index) => {
					if (item !== '') {
						jsonlist.push({
							k: this.bottomClassifyList[index].title,
							v: this.bottomClassifyList[index].list[item].id
						})
					}

				})

				this.fromData.jsonStr = jsonlist.length == 0 ? '' : JSON.stringify(jsonlist)
				this.shopList = []
				this.fromData.page = 1
				this.goodslist()
				this.$forceUpdate()
			},
			chooseIndexClick(index) {
				if (index == 1) {
					this.navigationList.splice(2, 2)
					this.fromData.cid = ""
					this.bottomClassifyList = []
					this.bottomClassifyList.push({
						title: "分类",
						type: 1,
						list: this.classifyList[this.navigationList[1].url].children
					})
				} else if (index == 2) {
					this.navigationList.splice(3, 1)
				}
				for (var i = 0; i < this.thereClassifyIndex.length; i++) {
					this.thereClassifyIndex[i] = ""
				}
				this.fromData.jsonStr = ""
				this.shopList = []
				this.fromData.page = 1
				this.goodslist()
				this.$forceUpdate()
			},
			searchClick(res) {
				//console.log(res.shoptypes)
				res.shoptypes=this.shoptypes
				if(this.shoptypes==0){
					this.tier = 0
					this.navigationList = [{
						name: "首页",
						url: "/",
						type: 0
					}]
					this.thereClassifyIndex = []
					this.fromData.condition = res.condition
					this.fromData.pid = ""
					this.fromData.cid = ""
					this.fromData.jsonStr = ""
					this.shopList = []
					this.fromData.page = 1
					this.firstClassify()
				}else{
					this.companycompanyList(res.condition) 
				}
				
			},
			// 企业列表
			// companycompanyList(condition) {
			// 	companycompanyList({
			// 		condition:condition
			// 	}).then(res => {
			// 		 //console.log("企业列表", res);
			// 		this.companyList = res.data
			// 		//this.total=
			// 	})
			// },
		}
	}
</script>

<style lang="less">
	@import url("shop.less");
</style>
