<template>
	<div id="Breadcrumb">
		<div class="breadcrumb-box">
			<div class="breadcrumb flc">
				<div class="flc" v-for="(item,index) in navigationList" :key="index">
					<router-link v-if="item.type == 0" class="breadcrumb-a text-none" :to="item.url">
						{{item.name}}
					</router-link>
					<div v-if="item.type == 1" class="breadcrumb-a text-none" @click="chooseIndexClick(index)">
						{{item.name}}
					</div>
					<div v-if="item.type == 2" class="breadcrumb-a text-none">
						{{item.name}}
					</div>
					<div v-if="item.type == 3" class="breadcrumb-a text-none">
						{{item.name}}
					</div>
					<img v-if="index != (navigationList.length-1)" class="breadcrumb-j"
						src="../../assets/img/arrows.png">
				</div>
				<!-- <div class="breadcrumb-b flc">
					<div class="breadcrumb-b-t">
						劳保
					</div>
					<img class="breadcrumb-b-x" src="../../assets/img/back.png">
				</div>
				<img class="breadcrumb-j" src="../../assets/img/arrows.png">
				<div class="breadcrumb-b flc">
					<div class="breadcrumb-b-t">
						劳保
					</div>
					<img class="breadcrumb-b-x" src="../../assets/img/back.png">
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Breadcrumb",
		props: {
			navigationList: {
				type: Array,
				default () {
					return []
				}
			}
		},
		created() {
			// console.log(this.$route.params.first,this.$route.params.firstname);
		},
		methods: {
			chooseIndexClick(index) {
				if (index == 1 || index == 2) {
					this.$emit("chooseIndexClick", index)
				}
			}
		}
	}
</script>

<style lang="less">
	@import url("breadcrumb.less");
</style>
