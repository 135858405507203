<template>
	<div id="ClassifyChoose">
		<div class="ClassifyChoose" > <!-- @mouseover="mouseoverMore" @mouseleave="mouseleaveMore" -->
			<div class="shopbox-t flcc">
				<img class="shopbox-t-i" src="../../assets/img/shop1.png">
				<div class="shopbox-t-t">
					全部商品分类
				</div>

				<!-- 一级分类 -->
				<div class="classify-one" v-if="isShowone">
					<div class="classify-one-i" v-for="(item,index) in classifyList" :key="index"
						@mouseover="mouseoverOne(index)" @mouseleave="mouseleaveOne()"
						:style="{backgroundColor: index === classifyOneIndex ? '#F2F2F2' : ''}"
						@click.stop="screenClick(item.id)">
						{{item.title}}

						<div class="tabbox-posi" v-if="isClassify && index === classifyOneIndex">
							<div class="tabbox-posi-one">
								<!-- <div class="tabbox-posi-one-title">
									劳保
								</div> -->
								<div class="tabbox-posi-two" v-for="(item2,index2) in item.children" :key="index2"
									@click.stop="screenClick(item.id+'-'+item2.id)">
									<div class="tabbox-posi-two-title">
										{{item2.title}}
									</div>
									<!-- <img class="tabbox-posi-two-pic" src="../../assets/img/arrows.png"> -->
									<div class="tabbox-posi-three flw" v-if="item2.children.length> 0">
										<div class="tabbox-posi-three-i"
											v-for="(item3,index3) in JSON.parse(item2.children[0].json)" :key="index3"
											@click.stop="screenClick(item.id+'-'+item2.id+'-'+item3.v)">
											{{item3.key}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ClassifyChoose",
		props: {
			classifyList: {
				type: Array,
				default () {
					return []
				}
			},
			isShop: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				isShowone: false,
				classifyOneIndex: "",
				isClassify: false
			}
		},
		methods: {
			mouseoverMore() {
				if (this.isShop) {
					this.isShowone = true
				}
			},
			mouseleaveMore() {
				this.isShowone = false
			},
			mouseoverOne(e) {
				this.classifyOneIndex = e
				this.isClassify = true
			},
			mouseleaveOne() {
				this.classifyOneIndex = ''
				this.isClassify = false
			},
			screenClick(cc) {
				this.$emit("screenClick", cc)
			}
		}
	}
</script>

<style lang="less">
	@import url("classify_choose.less");
</style>
