<template>
	<div id="ShopList">
		<div class="shoplist flw">
			<router-link class="shoplist-i text-none" :to="{name:'shop_detail',query:{id:item.id}}"
				:style="{marginRight : index % 5 == 4 ? '0px' : '6px' }" v-for="(item,index) in shopList" :key="index">

				<el-image class="shoplist-i-pic" :src="item.cover" :fit="'cover'">
				</el-image>
				<div class="shoplist-i-t">
					¥{{item.price}}
				</div>
				<div class="shoplist-i-c line-two">
					{{item.title}}
				</div>
				<!-- <div class="shoplist-i-b flcs">
					<img class="shoplist-i-b-l" src="../../assets/img/collect.png">
					<div class="shoplist-i-b-r flcc">
						<img class="shoplist-i-b-r-l" src="../../assets/img/car.png">
						<div class="shoplist-i-b-r-r">
							加入购物车
						</div>
					</div>
				</div> -->
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ShopList",
		props: {
			shopList: {
				type: Array,
				default () {
					return []
				}
			}
		},
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style>
</style>
